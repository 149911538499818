import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Provider from 'react-auth-guard';
// import { sessionIsActive } from "./utils/coreutils";
import sjcl from "sjcl";
import { useHistory } from 'react-router-dom';
import Cookies from "universal-cookie";
// import { analytics } from "./utils/firebaseLoader";

// import navigation from "../../_nav";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const ENC_KEY = process.env.REACT_APP_ENC_KEY;

// class App extends Component {

//   render() {
//     return (
//       <HashRouter>
//           <React.Suspense fallback={loading()}>
//             <Switch>
//               <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
//               <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
//               <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
//               <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
//               <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
//             </Switch>
//           </React.Suspense>
//       </HashRouter>
//     );
//   }
// }

const App = ({ props }) => {
  const history = useHistory();
  const [alertMessage, setAlertMessage] = useState(null);
  const [nav, setnav] = useState({ items: [] });
  const analytics = "";
  // debugger;

  useEffect(() => {
    if (nav.items.length === 0) {
      const navStorageEncrypted = window.localStorage.getItem("jagain_n");
      if (navStorageEncrypted) {
        const navStorageDecrypted = sjcl.decrypt(ENC_KEY, navStorageEncrypted);
        const navStorage = JSON.parse(navStorageDecrypted);
        if (navStorage) {
          setnav(navStorage);

        }

      } else {
        if(process.env.REACT_APP_AUTH_VERIFICATION === "false") return;
        const cookie = new Cookies();
        cookie.remove('jagain_c', { path: "/", domain: ".jagain.com" });
        history.push("/login");
      }
    }
  }, [setnav]);

  return (
    <Provider fetchUser={() => new Promise(resolve => resolve())}
      onLogin={() => alert('Welcome to the magical world of the internet')}
      onLogout={() => alert('You\'ve been logged out')}
    >
      {({ authenticating, authenticated }) => (
        /* Render your application */
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              path="/dashboard"
              name="Dashboard"
              render={props => (
                <DefaultLayout
                  {...props}
                  setAlertMessage={setAlertMessage}
                  nav={nav}
                  setnav={setnav}
                  analytics={analytics}
                />
              )}
            />
            <Route
              exact
              path="/login"
              name="Login Page"
              render={props => (
                <Login
                  {...props}
                  setAlertMessage={setAlertMessage}
                  alertMessage={alertMessage}
                  setnav={setnav}
                  analytics={analytics}
                />
              )}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={props => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={props => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={props => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={props => (
                <DefaultLayout
                  {...props}
                  setAlertMessage={setAlertMessage}
                  nav={nav}
                  setnav={setnav}
                  analytics={analytics}
                />
              )}
            />
          </Switch>
        </React.Suspense>
      )}
    </Provider>
  );
};

export default App;
